<template>
  <div class="main" ref="main">
    <a-layout class="mlayout">
      <a-layout-header class="layhead">
        <top-bar msg="" showback="0" />
      </a-layout-header>
      <a-layout>
        <a-layout-sider theme="dark">
          <sider-bar />
        </a-layout-sider>
        <a-layout-content class="adminalcontent">
          <div class="maindiv">
            <a-breadcrumb class="bread">
              <a-breadcrumb-item>
                <router-link to="/index"> 控制台 </router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item> 案例管理 </a-breadcrumb-item>
            </a-breadcrumb>

            <div class="searchbar">
              <a-row>
                <a-col :span="22">
                  <a-select
                    :value="query.class1"
                    placeholder="项目"
                    style="min-width: 200px; width: auto; margin-right: 10px"
                    @change="handleClass1Changef"
                  >
                    <a-select-option key=""> 请选择项目 </a-select-option>
                    <a-select-option v-for="item in classlistf" :key="item.id">
                      {{ item.cname }}（{{ item.code }}）
                    </a-select-option>
                  </a-select>

                  <a-select
                    placeholder="种类"
                    :value="query.class2"
                    style="min-width: 200px; width: auto; margin-right: 10px"
                    @change="handleClass2Changef"
                  >
                    <a-select-option key=""> 请选择分类 </a-select-option>
                    <a-select-option v-for="item in classlist2f" :key="item.id">
                      {{ item.cname }}（{{ item.code }}）
                    </a-select-option>
                  </a-select>

                  <a-input-search
                    placeholder="关键字搜索"
                    v-model="query.searchkey"
                    enter-button
                    style="width: 200px"
                    @search="onSearch"
                  />
                </a-col>
                <a-col :span="0"> </a-col>
              </a-row>
            </div>

            <a-row>
              <a-col :span="22" class="tabletools">
                <a-button
                  v-if="thisrule > 1 && logininfo.ksa_level < 3"
                  type="primary"
                  @click="add"
                  style="margin-right: 10px"
                >
                  <a-icon type="plus" /> 添加
                </a-button>

                <a-button
                  v-if="thisrule == 3 && logininfo.ksa_level < 3"
                  type="primary"
                  @click="deleteAll"
                >
                  <a-icon type="delete" /> 批量删除
                </a-button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <!-- <a-button
                  v-if="thisrule == 3"
                  type="primary"
                  @click="downloadEXCEL"
                >
                  <a-icon type="plus" /> 导出EXCEL
                </a-button>
                &nbsp;&nbsp;&nbsp;&nbsp; -->
                <span style="margin-left: 20px"
                  >共（<b>{{ pagination.total }}</b
                  >）条信息</span
                >
              </a-col>
              <a-col :span="4"></a-col>
              <a-col :span="4"> </a-col>
            </a-row>
            <div
              style="height: calc(100%-100px); width: 100%; overflow-x: auto"
            >
              <a-table
                class="mtable photo"
                :loading="loading"
                :pagination="pagination"
                :columns="columns"
                :data-source="data"
                @change="handleTableChange"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
              >
                <span
                  slot="unitText"
                  class="nmitem"
                  slot-scope="text, record"
                  style="font-size: 12px"
                >
                  <!-- <span v-if="text == 0"> 未分配 </span>
                <b v-else style="color: green"> 已分配 </b> -->
                  <template v-if="record.unid != '0'">
                    {{ record.unit.title }}
                  </template>
                  <template v-else>
                    <span style="color: #aaa">未分配</span>
                  </template>
                </span>

                <span
                  slot="minfoText"
                  class="nmitem"
                  slot-scope="text, record"
                  style="font-size: 12px"
                >
                  <!-- <img :src="record.headimg" v-if="record.headimg" />
                  <img v-else src="../assets/user.png" />
                  {{ record.nickname }}({{ record.uname }}) -->

                  <template v-if="record.openid">
                    <img :src="record.headimgurl" v-if="record.headimgurl" />
                    <img v-else src="../assets/user.png" />
                    <br />
                    {{ decodeURIComponent(record.nickname) }}

                    <template v-if="record.kindname != ''">
                      ({{ record.kindname }})
                    </template>
                  </template>
                  <template v-else>
                    <span>/</span>
                  </template>
                </span>

                <span
                  slot="indexText"
                  class="nmitem"
                  slot-scope="text, record, index"
                  >{{ index + 1 }}</span
                >

                <span
                  slot="class1Text"
                  class="nmitem"
                  slot-scope="text, record"
                >
                  <template v-for="(item, index) in record.cname1">
                    <span :key="index">{{ item }}&nbsp;&nbsp;</span>
                  </template>
                </span>
                <span
                  slot="class2Text"
                  class="nmitem"
                  slot-scope="text, record"
                >
                  <template v-for="(item, index) in record.cname2">
                    <span :key="index">{{ item }}&nbsp;&nbsp;</span>
                  </template>
                </span>

                <span slot="showText" class="nmitem" slot-scope="text">
                  <a-icon v-if="text == 1" type="check" style="color: green" />
                  <a-icon v-if="text == 0" type="close" style="color: red" />
                </span>

                <span slot="hotText" class="nmitem" slot-scope="text">
                  <a-icon v-if="text == 1" type="check" style="color: green" />
                  <a-icon v-if="text == 0" type="close" style="color: red" />
                </span>

                <span slot="uinfoText" class="nmitem" slot-scope="text, record"
                  >{{ record.uname }}<br />{{ record.idcard }}</span
                >

                <span slot="overText" class="nmitem" slot-scope="text, record">
                  <template v-if="record.overcount > 0">
                    <b style="color: #ff0000">record.overcount</b>次
                  </template>
                  <template v-else> 否 </template>
                </span>

                <span slot="photoarea" class="photo" slot-scope="text">
                  <img :src="text" v-if="text != ''" @click="ShowPhoto(text)" />
                  <img v-else src="../assets/user.png" />
                </span>

                <span slot="videoarea" class="pvideo" slot-scope="text, record">
                  <video
                    :poster="record.poster"
                    class="videoplayer"
                    :src="record.video"
                    alt="avatar"
                    controls
                  />
                </span>

                <b
                  slot="blodText"
                  class="nmitem"
                  slot-scope="text"
                  style="color: #000; font-size: 16px"
                  >{{ text }}</b
                >

                <span slot="action" slot-scope="text, record">
                  <template v-if="thisrule == 3 && logininfo.ksa_level < 4">
                    <a @click="onDel(record.id)">删除</a>
                    <a-divider type="vertical" />
                    <a @click="onEdit(record)">修改</a>
                  </template>
                </span>
              </a-table>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>

    <a-modal
      :title="modaltitle"
      :visible="visibleAddModal"
      :confirm-loading="confirmLoading"
      ok-text="确认"
      cancel-text="取消"
      @ok="handleAddOk"
      @cancel="handleAddCancel"
      :width="1200"
    >
      <a-form :form="form" class="myform">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="图片"
        >
          <a-upload
            action=""
            list-type="picture-card"
            :show-upload-list="false"
            :before-upload="
              (file) => {
                beforeImgUpload(file);
              }
            "
          >
            <img v-if="imgurl" class="tmimage" :src="imgurl" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>

          <a-input
            hidden
            v-decorator="['imgurl', { rules: [{ required: false }] }]"
            placeholder=""
          />
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="项目"
        >
          <a-select
            mode="multiple"
            v-decorator="[
              'class1',
              { rules: [{ required: true, message: '请选择项目' }] },
            ]"
            style="margin-right: 10px"
            @change="handleClass1Change"
          >
            <a-select-option v-for="item in classlist" :key="item.id">
              {{ item.cname }}（{{ item.code }}）
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="分类"
        >
          <a-select
            mode="multiple"
            v-decorator="[
              'class2',
              { rules: [{ required: true, message: '请选择分类' }] },
            ]"
            :v-model="class2"
            style=""
          >
            <a-select-option v-for="item in classlist2" :key="item.id">
              {{ item.cname }}（{{ item.code }}）
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="关键词"
        >
          <a-input
            style="width: 100%"
            v-decorator="['keywords']"
            :maxLength="50"
            placeholder="多个关键词请用“,”分开"
          />
        </a-form-item>

        <!-- 
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="视频"
        >
          <a-upload
            :multiple="true"
            action="/vdb/wxapp/ksjy/kapi/upload_file.php"
            :headers="headers"
            @change="handleVideoChange"
            list-type="picture-card"
            :show-upload-list="false"
            :before-upload="beforeVideoUpload"
          >
            <video
              v-if="videoUrl"
              class="tmimage"
              :src="videoUrl"
              alt="avatar"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传视频</div>
            </div>
          </a-upload>
          <a-input
            hidden
            v-decorator="['videourl', { rules: [{ required: false }] }]"
            placeholder=""
          />
        </a-form-item> -->

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="标题"
        >
          <a-input
            style="width: 100%"
            v-decorator="[
              'pname',
              { rules: [{ required: true, message: '请设置标题' }] },
            ]"
            :maxLength="50"
            placeholder=""
          />
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="描述"
        >
          <a-textarea
            style="width: 100%; line-height: 24px"
            auto-height
            v-decorator="[
              'des',
              {
                rules: [{ required: true, message: '请填写简介' }],
              },
            ]"
          />
        </a-form-item>
        <!-- 
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="讲师"
        >
          <a-input
            style="width: 200px"
            v-decorator="[
              'js',
              { rules: [{ required: true, message: '请设置讲师' }] },
            ]"
            :maxLength="50"
            placeholder=""
          />
        </a-form-item> -->

        <!-- <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="有效期"
        >
          <a-input
            type="hidden"
            style="width: 200px"
            v-decorator="['yxdate']"
            :maxLength="50"
            placeholder=""
          />
          <a-date-picker
            :value="yxdate"
            format="YYYY/MM/DD"
            @change="yxdateChange"
            :allowClear="true"
          />

          <label style="margin-left: 50px">长期有效:&nbsp;&nbsp;</label>

          <a-switch
            v-decorator="['longyx']"
            @click="handleLongyxClick"
            v-model="longyxchecked"
          >
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </a-form-item> -->

        <!-- <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="长期有效"
        >
          
        </a-form-item> -->

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="显示"
        >
          <a-switch v-decorator="['show']" v-model="showchecked">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="首页"
          style="display: none"
        >
          <a-switch v-decorator="['hot']" v-model="hotchecked">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </a-form-item>
        <!-- <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="实力好课堂"
        >
          <a-switch v-decorator="['good']" v-model="goodchecked">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </a-form-item> -->

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="跳转连接"
        >
          <a-input
            style="width: 100%"
            v-decorator="['url']"
            :maxLength="50"
            placeholder=""
          />
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="简介"
        >
          <input type="hidden" v-decorator="['content']" placeholder="" />
          <my-editor
            @HtmlonChange="HtmlonChange"
            :content="content"
          ></my-editor>
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="权重"
        >
          <a-input-number
            v-decorator="[`weight`]"
            placeholder=""
            style="width: 120px"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;(数值越大排名越靠前)
          <br /><br />
          <hr />
        </a-form-item>

        <!-- <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="课程视频"
        >
          <a-row
            v-for="(item, index) in videoary"
            :key="item.id"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
            "
          >
            <a-col
              :span="6"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
              "
            >
              <video class="tmimage" controls :src="item.surl" alt="avatar" />
            </a-col>
            <a-col
              :span="18"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
              "
            >
              <a-input
                style="width: 300px"
                :maxLength="50"
                placeholder="视频标题"
                :value="item.title"
                @change="
                  (e) => {
                    onTitleChange(e, index);
                  }
                "
                :data-index="index"
              />

              <a-checkbox
                style="margin-left: 20px"
                :checked="item.lock == '1'"
                @change="
                  () => {
                    onLockChange(index);
                  }
                "
              >
                付费视频
              </a-checkbox>

              <a-button
                :disabled="index == 0"
                style="margin-left: 20px"
                @click="
                  () => {
                    upvideo(index);
                  }
                "
              >
                <a-icon type="arrow-up" /> 上移
              </a-button>

              <a-button
                style="margin-left: 20px"
                @click="
                  () => {
                    delvideo(index);
                  }
                "
              >
                <a-icon type="close" /> 删除
              </a-button>
            </a-col>
          </a-row>

          <a-row
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
            "
          >
            <a-col :span="6">
              <a-upload
                name="file"
                :multiple="true"
                action="/vdb/wxapp/ksjy/kapi/upload_file2.php?type=cvideo"
                :headers="headers"
                @change="handleVideoChange"
                :show-upload-list="false"
                :before-upload="beforeVideoUpload"
              >
                <a-button> <a-icon type="upload" /> 上传视频 </a-button>
              </a-upload>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="课程附件"
          style="border-top: 1px solid #ccc; padding-top: 20px"
        >
          <a-row
            v-for="(item, index) in fileary"
            :key="item.id"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
            "
          >
            <a-col :span="6">
              <a
                target="_blank"
                rel="noopener noreferrer"
                :title="item.title"
                :href="item.surl"
                class="ant-upload-list-item-name"
                ><a-icon type="file-text" /> 点击查看</a
              >
            </a-col>
            <a-col
              :span="18"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
              "
            >
              <a-input
                style="width: 300px"
                :maxLength="50"
                placeholder="附件标题"
                :value="item.title"
                @change="
                  (e) => {
                    onTitle2Change(e, index);
                  }
                "
                :data-index="index"
              />

              <a-checkbox
                style="margin-left: 20px"
                :checked="item.lock == '1'"
                @change="
                  () => {
                    onLock2Change(index);
                  }
                "
              >
                付费课件
              </a-checkbox>

              <a-button
                :disabled="index == 0"
                style="margin-left: 20px"
                @click="
                  () => {
                    upfile(index);
                  }
                "
              >
                <a-icon type="arrow-up" /> 上移
              </a-button>

              <a-button
                style="margin-left: 20px"
                @click="
                  () => {
                    delfile(index);
                  }
                "
              >
                <a-icon type="close" /> 删除
              </a-button>
            </a-col>
            <a-col :span="6"> </a-col>
          </a-row>

          <a-row
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
            "
          >
            <a-col :span="6">
              <a-upload
                name="file"
                :multiple="true"
                action="/vdb/wxapp/ksjy/kapi/upload_file2.php?type=cfile"
                :headers="headers"
                @change="handleFileChange"
                :show-upload-list="false"
                :before-upload="beforeFileUpload"
              >
                <a-button> <a-icon type="upload" /> 上传附件 </a-button>
              </a-upload>
            </a-col>
          </a-row>
        </a-form-item> -->

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="关键词(英)"
        >
          <a-input
            style="width: 100%"
            v-decorator="['enkeywords']"
            :maxLength="50"
            placeholder="多个关键词请用“,”分开"
          />
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="标题(英)"
        >
          <a-input
            style="width: 100%"
            v-decorator="['enpname']"
            :maxLength="50"
            placeholder=""
          />
        </a-form-item>

        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="简介(英)"
        >
          <input type="hidden" v-decorator="['encontent']" placeholder="" />
          <my-editor
            @HtmlonChange="HtmlEnonChange"
            :content="encontent"
          ></my-editor>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  fetchClass,
  delCase,
  uploadFile,
  uploadImage,
  addCase,
  updateCase,
  fetchCase,
} from "../api/index";
// const Base64 = require('js-base64').Base64
import global from "../common";
import TopBar from "../components/TopBar.vue";
import SiderBar from "../components/Sider.vue";
import FooterBar from "../components/FooterBar.vue";
import MyEditor from "../components/MyEditor.vue";
import moment from "moment";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const columns = [
  {
    title: "#",
    dataIndex: "id",
    width: 60,
    // fixed: "left",
    scopedSlots: { customRender: "indexText" },
  },
  // {
  //   title: "视频",
  //   dataIndex: "poster",
  //   width: 70,
  //   // fixed: "left",
  //   scopedSlots: { customRender: "videoarea" },
  // },
  {
    title: "TITLE",
    dataIndex: "pname",
    // width: 300,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "项目",
    dataIndex: "cname1",
    // width: 300,
    scopedSlots: { customRender: "class1Text" },
  },
  {
    title: "分类",
    dataIndex: "cname2",
    // width: 300,
    scopedSlots: { customRender: "class2Text" },
  },
  {
    title: "描述",
    dataIndex: "des",
    width: 400,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "权重",
    dataIndex: "weight",
    // width: 300,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "首页",
    dataIndex: "hot",
    width: 70,
    scopedSlots: { customRender: "hotText" },
  },
  {
    title: "显示",
    dataIndex: "show",
    width: 70,
    scopedSlots: { customRender: "showText" },
  },
  {
    title: "添加时间",
    dataIndex: "addtime",
    width: 120,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "操作",
    key: "action",
    width: 160,
    scopedSlots: { customRender: "action" },
  },
];

const innerColumns = [
  {
    title: "文件名",
    dataIndex: "name",
  },
  {
    title: "文件",
    dataIndex: "url",
    scopedSlots: { customRender: "files" },
  },
];

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const formTailLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 10, offset: 6 },
};

export default {
  name: "home",
  components: {
    TopBar,
    FooterBar,
    SiderBar,
    MyEditor,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      headers: {
        authorization: "authorization-text",
      },
      visibleAddModal: false,

      uploading: false,

      fileList: [
        // {
        //   uid: '-1',
        //   name: 'xxx.png',
        //   status: 'done',
        //   url: 'http://www.baidu.com/xxx.png',
        // },
      ],

      modaltitle: "添加",
      data: [],
      columns,
      innerColumns,
      pagination: {},
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,

      query: {
        unid: "",
        class1: "",
        class2: "",
        uname: "",
        starttime: "",
        finaltime: "",
      },
      tableData: [],
      pageTotal: 0,
      pageSize: 50,
      page: 1,

      confirmLoading: false,

      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      formItemLayout,
      formTailLayout,
      action: "add",
      editid: 0,

      editrecord: [],

      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 20, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },

      thisrule: 0,
      logininfo: [],
      visibleModalPhoto: false,
      spfphoto: "",
      spfuname: "",
      tablescrolly: 400,

      classlist: [],
      classlist2: [],
      classlistf: [],
      classlist2f: [],
      class1: 0,
      class2: 0,

      content: "",
      encontent: "",

      longyxchecked: true,

      showchecked: false,

      hotchecked: false,

      goodchecked: false,

      yxdate: "",

      videoary: [],

      fileary: [],

      imgurl: "",

      imgary: [],
    };
  },
  mounted() {
    this.fetch();
    this.thisrule = global.checkrule();
    // alert(this.thisrule);
    this.logininfo = global.getLoginA();

    this.tablescrolly = this.$refs.main.offsetHeight - 380;
    this.getClassList();
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    moment,

    upvideo(index) {
      var videoaryf = [];
      if (index != 0) {
        let videoary = this.videoary;
        videoary.map((item) => {
          videoaryf.push(item);
        });
        videoaryf[index - 1] = this.videoary[index];
        videoaryf[index] = this.videoary[index - 1];

        this.videoary = videoaryf;
      }
    },
    upfile(index) {
      var filearyf = [];
      if (index != 0) {
        let fileary = this.fileary;
        fileary.map((item) => {
          filearyf.push(item);
        });
        filearyf[index - 1] = this.fileary[index];
        filearyf[index] = this.fileary[index - 1];

        this.fileary = filearyf;
      }
    },
    onTitleChange(e, index) {
      // console.log("onTitleChange:", e.target.value, index);
      this.videoary[index].title = e.target.value;
    },
    onTitle2Change(e, index) {
      // console.log("onTitleChange:", e.target.value, index);
      this.fileary[index].title = e.target.value;
    },
    onLockChange(index) {
      if (this.videoary[index].lock == "0") {
        this.videoary[index].lock = "1";
      } else if (this.videoary[index].lock == "1") {
        this.videoary[index].lock = "0";
      }
    },
    onLock2Change(index) {
      if (this.fileary[index].lock == "1") {
        this.fileary[index].lock = "0";
      } else {
        this.fileary[index].lock = "1";
      }
    },
    delfile(index) {
      this.fileary = this.fileary.filter((item, idx) => {
        return idx != index;
      });

      console.log("delfile:", this.fileary);
    },

    delvideo(index) {
      this.videoary = this.videoary.filter((item, idx) => {
        return idx != index;
      });

      console.log("delvideo:", this.videoary);
    },

    handleLongyxClick(e) {
      console.log("handleLongyxClick:", this.longyxchecked);
      if (this.longyxchecked) {
        this.yxdate = "";
        this.form.setFieldsValue({
          yxdate: "",
        });
      }
    },
    yxdateChange(date, dateString) {
      if (dateString == "") {
        this.yxdate = "";
        this.form.setFieldsValue({
          yxdate: "",
        });
        this.longyxchecked = true;
      } else {
        this.yxdate = moment(dateString, "YYYY/MM/DD");
        this.form.setFieldsValue({
          yxdate: dateString,
        });
        this.longyxchecked = false;
      }
    },

    handleClass1Changef(e) {
      this.query.class1 = e;
      console.log(e);
      this.resetpage();
      this.setClassListf(e, "");
    },
    handleClass2Changef(e) {
      this.query.class2 = e;
      this.resetpage();
      this.fetch();
    },

    setClassListf(class1 = "", class2 = "") {
      if (class1 != "") {
        let listary = this.classlist.filter((it) => {
          return it.id == class1;
        })[0];

        let list = [];

        if (listary.list) {
          list = this.classlist.filter((it) => {
            return it.id == class1;
          })[0].list;
        }

        this.classlist2f = list;
      } else {
        this.classlist2f = [];
      }

      this.query.class2 = class2;
      this.fetch();
    },

    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        console.log(
          "%cfetchClass",
          "color: green;font-size: 14px;font-weight: bold;",
          res
        );
        that.classlist = res.results.list;
        that.classlistf = res.results.list;
      });
    },

    handleClass1Change(e) {
      console.log(e);
      this.setClassList(e, []);
    },

    setClassList(class1 = [], class2 = []) {
      if (class1 != []) {
        let listary = this.classlist.filter((it) => {
          return class1.indexOf(it.id) > -1;
        })[0];

        this.form.setFieldsValue({
          class2: [],
        });

        let list = [],
          nlist = [];

        console.log("listary:", listary);

        if (listary) {
          if (listary.list) {
            list = this.classlist.filter((it) => {
              return class1.indexOf(it.id) > -1;
            });
            nlist = [];
            list.map((item, index) => {
              item.list.map((item2) => {
                nlist.push(item2);
              });
            });

            // console.log("nlist:", nlist);
          }
        }

        this.classlist2 = nlist;

        console.log("nlist:", nlist);

        if (class2.length == 0) {
          if (nlist.length > 0) {
            class2 = [nlist[0].id];
          }
        }

        if (list.length > 0) {
          // console.log("setClassList:", class2);
          this.form.setFieldsValue({
            class2: class2,
          });
        }
      }
    },

    resetpage() {
      this.page = 1;
      const pagination = { ...this.pagination };
      pagination.current = 1;
      this.pagination = pagination;
    },

    add() {
      let that = this;
      this.modaltitle = "添加";
      this.action = "add";
      this.editid = 0;
      this.visibleAddModal = true;
      this.videoary = [];
      this.fileary = [];
      this.imgary = [];
      this.content = "";
      this.encontent = "";
      this.fileList = [];
      this.imgurl = "";

      setTimeout(() => {
        that.form.resetFields();
        that.form.setFieldsValue({
          show: true,
          hot: false,
        });
        that.showchecked = true;
        that.hotchecked = false;
        that.goodchecked = false;
        that.longyxchecked = true;
      });
    },
    handleAddOk() {
      // this.confirmLoading = true;
      const that = this;
      this.form.validateFields((err, values) => {
        console.log("Received values of form: ", values);

        if (!err) {
          console.info("success");

          let postdata = {
            show: values.show,
            hot: values.hot,
            pname: values.pname,
            des: values.des,
            content: values.content,
            weight: values.weight,
            class1: values.class1.join(","),
            class2: values.class2.join(","),
            keywords: values.keywords,
            imgary: this.imgary,
            url: values.url,

            enpname: values.enpname,
            enkeywords: values.enkeywords,
            encontent: values.encontent,
          };

          console.log("postdata:", postdata);

          // alert(this.action);

          // return false;

          if (this.action == "add") {
            addCase(postdata).then((res) => {
              console.log("addCourse:", res);
              let results = res.results;
              if (results.message == "1") {
                that.$message.success("添加成功");
                that.visibleAddModal = false;
                that.form.resetFields();
                that.fetch();
              }
              that.confirmLoading = false;
              that.visibleAddModal = false;
            });
          } else if (this.action == "edit") {
            postdata.id = this.editid;
            updateCase(postdata).then((res) => {
              console.log("updateCourse:", res);
              let results = res.results;
              if (results.message == "1") {
                that.$message.success("修改成功");
                that.visibleAddModal = false;
                that.form.resetFields();
                that.fetch();
              }
              that.confirmLoading = false;
              that.visibleAddModal = false;
            });
          }
        } else {
          that.confirmLoading = false;
        }
      });
    },
    handleAddCancel() {
      this.visibleAddModal = false;
    },

    handleUnitChangef(e) {
      this.query.unid = e;
      this.fetch();
    },
    downloadEXCEL() {
      // alert(location.host);
      window.open(
        "http://" +
          location.host +
          "/ks/api/table/outexcel.php?class2=" +
          this.query.class2 +
          "&starttime=" +
          this.query.starttime +
          "&finaltime=" +
          this.query.finaltime
      );
    },
    onSearch(e) {
      this.fetch();
    },

    onChangeRangePicker(date, dateString) {
      this.query.starttime = dateString[0];
      this.query.finaltime = dateString[1];
      this.fetch();
    },

    cutImgData(img, s = 0, size = 300, callback) {
      var image = new Image();
      image.src = img;
      image.onload = function () {
        var degree = 0,
          drawWidth,
          drawHeight,
          width,
          height;
        drawWidth = this.naturalWidth;
        drawHeight = this.naturalHeight;

        //以下改变一下图片大小

        var maxSide = Math.max(drawWidth, drawHeight);
        var minSide = Math.min(drawWidth, drawHeight);

        // alert(123 + "__bsize:" + size);

        if (s == 0) {
          if (maxSide > size) {
            /*alert(minSide);
                minSide = minSide / maxSide * 500;*/

            minSide = minSide * (size / maxSide);
            maxSide = size;
            if (drawWidth > drawHeight) {
              drawWidth = maxSide;
              drawHeight = minSide;
            } else {
              drawWidth = minSide;
              drawHeight = maxSide;
            }
          }
        } else if (s == 1) {
          // if (minSide > size) {

          /*alert(minSide);
            minSide = minSide / maxSide * 500;*/

          maxSide = maxSide * (size / minSide);
          minSide = size;
          if (drawWidth > drawHeight) {
            drawWidth = maxSide;
            drawHeight = minSide;
          } else {
            drawWidth = minSide;
            drawHeight = maxSide;
          }
          // }
        }

        var canvas = document.createElement("canvas");

        canvas.width = width = drawWidth;

        canvas.height = height = drawHeight;

        var context = canvas.getContext("2d");

        context.rotate((degree * Math.PI) / 180);

        context.drawImage(this, 0, 0, drawWidth, drawHeight);

        img = canvas.toDataURL("image/png", 1);

        return callback(img);
      };
    },

    beforeImgUpload(file) {
      const that = this;
      const { form } = this;
      that.uploading = true;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        that.$message.error("你只能上传jpeg或者png文件!");
      }
      console.log("file", file);
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        that.$message.error("图片必须要小于 4MB!");
        that.uploading = false;
        return false;
      }
      getBase64(file, (imageUrl) => {
        that.cutImgData(imageUrl, 0, 500, (imageUrl) => {
          console.log("this.cutImgData:", imageUrl);

          that.imageUrl = "";

          that.uploading = false;
          // can use data-binding to set

          uploadImage({
            img: imageUrl,
            filename: file.name,
            kind: "info",
          }).then((res) => {
            console.log(res);
            let results = res.results;
            if (results.message == "1") {
              that.$message.success("上传成功");
              that.imgurl = global.baseUrl + results.url;
              setTimeout(() => {
                form.setFieldsValue({
                  imgurl: results.url,
                });
              });
              that.imgary = [
                {
                  url: results.url,
                },
              ];
            } else if (results.message == "0") {
              that.$message.wrong("上传失败");
            }
          });
        });
      });
      return isJpgOrPng && isLt2M;
    },

    handleVideoChange(info) {
      console.log(info.file);

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.videoUrl = global.baseUrl + info.file.response.url;
        // this.form.setFieldsValue({
        //   videourl: info.file.response.url,
        // });

        let videoary = this.videoary;

        videoary.push({
          surl: global.baseUrl + info.file.response.url,
          url: info.file.response.url,
          title: "",
          lock: "0",
        });

        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },

    beforeVideoUpload(file) {
      const isJpgOrPng = file.type === "video/mp4";
      if (!isJpgOrPng) {
        this.$message.error("请上传MP4视频!");
      }
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error("视频要小于 100MB!");
      }
      return isJpgOrPng && isLt2M;
    },

    handleFileChange(info) {
      console.log("1 this.fileary:", this);

      console.log(info.file);

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        let fileary = this.fileary;

        fileary.push({
          surl: global.baseUrl + info.file.response.url,
          url: info.file.response.url,
          title: "",
          lock: "0",
        });

        this.fileary = fileary;

        console.log("this.fileary:", this.fileary);

        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },

    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error("文件要小于 100MB!");
      }
      return isLt2M;
    },

    deleteAll() {
      let idsary = [];
      this.selectedRows.map((item, index) => {
        idsary.push(item.id);
      });
      this.selectedRowKeys = [];
      this.selectedRows = [];
      const ids = idsary.join(",");

      console.log("del:", ids);
      const that = this;
      this.$confirm({
        title: "提示",
        content: "确定删除?",
        okText: "删除",
        cancelText: "取消",
        onOk() {
          delCase({ id: ids }).then((res) => {
            console.log(
              "%cdel",
              "color: #FF0000;font-size: 18px;font-weight: bold;",
              res
            );
            that.fetch();
          });
        },
        onCancel() {},
      });
    },
    download() {
      this.confirmLoading = true;
      const that = this;
      downloadPfile().then((res) => {
        that.fetch();
        that.confirmLoading = false;
      });
    },

    ShowPhoto(text) {
      console.log("showphoto:", text);
      this.spfphoto = text;
      this.visibleModalPhoto = true;
    },

    handlePhoto() {
      this.visibleModalPhoto = false;
    },

    HtmlonChange(html) {
      // console.log("HtmlonChange:", html);
      this.form.setFieldsValue({
        content: html,
      });
    },
    HtmlEnonChange(html) {
      // console.log("HtmlonChange:", html);
      this.form.setFieldsValue({
        encontent: html,
      });
    },
    onEdit(record) {
      console.log("record:", record);
      let that = this;
      this.editrecord = record;
      // this.modaltitle = "修改";
      this.visibleAddModal = true;
      // alert(record.show == "1" ? true : false);
      setTimeout(() => {
        that.form.resetFields();
        that.unitlist = [];
        that.form.setFieldsValue({
          imgurl: record.imgurl,
          class1: record.class1,
          class2: record.class2,
          keywords: record.keywords,
          pname: record.pname,
          weight: record.weight,
          show: record.show == "1" ? true : false,
          hot: record.hot == "1" ? true : false,
          des: record.des,
          content: record.content,
          url: record.url,

          enkeywords: record.enkeywords,
          enpname: record.enpname,
          encontent: record.encontent,
        });

        that.showchecked = record.show == "1" ? true : false;

        that.hotchecked = record.hot == "1" ? true : false;

        that.imgary = record.imgary;

        that.fileary = record.fileary;

        record.videoary.map((item, index) => {
          record.videoary[index].surl = global.baseUrl + item.url;
        });

        record.fileary.map((item, index) => {
          record.fileary[index].surl = global.baseUrl + item.url;
        });

        that.videoary = record.videoary;

        that.action = "edit";

        that.editid = record.id;

        that.content = record.content;

        that.encontent = record.encontent;

        that.imgurl = global.baseUrl + record.imgurl;

        try {
          console.log(record.class1, record.class2);
          that.setClassList(record.class1, record.class2);
        } catch (e) {}
      });
    },
    onShow(id) {
      let that = this;
    },
    onDel(id) {
      console.log("del:", id);
      const that = this;

      this.$confirm({
        title: "提示",
        content: "确定删除?",
        okText: "删除",
        cancelText: "取消",
        onOk() {
          delCase({ id: id }).then((res) => {
            console.log(
              "%cdel",
              "color: #FF0000;font-size: 18px;font-weight: bold;",
              res
            );
            that.fetch();
          });
        },
        onCancel() {},
      });
    },
    onMag(id) {
      console.log("mag:", id);
    },
    handleTableChange(pagination, filters, sorter) {
      // console.log(pagination, filters, sorter);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.page = pagination.current;
      this.pagination = pager;
      this.fetch({
        pagesize: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },

    fetch(
      params = { page: this.page, pagesize: this.pageSize, query: this.query }
    ) {
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        console.log("fetchCase:", res);
        const pagination = { ...this.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = this.pageSize;
        that.loading = false;
        this.data = res.results.list;
        this.pagination = pagination;
      });
    },

    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mtable {
  font-size: 14px;
}
.nmtitle {
  font-size: 12px;
}
.nmitem {
  font-size: 14px;
}
.tmimage {
  max-width: 200px;
  max-height: 200px;
  border: 2px solid #cccccc;
}
.photo img {
  height: 40px;
  width: 40px;
  background-color: #fff;
  padding: 2px;
  -o-object-fit: contain;
  object-fit: contain;
}
.photodiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.spfphoto {
  max-width: 520px;
  min-width: 240px;
  min-height: 240px;
  background-color: #eee;
  object-fit: contain;
}

.ant-table-pagination.ant-pagination {
  position: fixed;
  float: left;
}

.videoplayer {
  width: 200px;
  height: 120px;
}
</style>
